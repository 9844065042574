<template>
  <button class="k-btn" v-bind:class="btnClass" :disabled="disabled">
    <Icon v-if="iconLeft !== undefined" :name="iconLeft" :size="_iconSize" />
    <slot />
    <Icon v-if="iconRight !== undefined" :name="iconRight" :size="_iconSize" />
  </button>
</template>

<script>
import Icon from "@/components/Icons/Icon";
export default {
  name: "Button",
  components: { Icon },
  props: [
    "theme" /* primary | secondary | tertiary */,
    "size" /* small | medium | large */,
    "iconRight",
    "iconLeft",
    "iconSize",
    "disabled",
    "shadow",
  ],
  computed: {
    _iconSize: function () {
      return this.iconSize || "thin";
    },
    btnClass: function () {
      let _class = {};

      switch (this.theme) {
        case "primary":
          _class["k-btn-primary"] = true;
          _class["k-btn-primary-shadow"] = this.shadow === true;
          break;
        case "secondary":
          _class["k-btn-secondary"] = true;
          break;
        case "tertiary":
          _class["k-btn-tertiary"] = true;
          break;
        default:
          _class["k-btn-primary"] = true;
          break;
      }

      switch (this.size) {
        case "small":
          _class["k-btn-small"] = true;
          break;
        case "medium":
          _class["k-btn-medium"] = true;
          break;
        case "large":
          _class["k-btn-large"] = true;
          break;
        default:
          _class["k-btn-medium"] = true;
          break;
      }

      return _class;
    },
  },
};
</script>

<style scoped>
.k-btn {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /*width: intrinsic;           !* Safari/WebKit uses a non-standard name *!*/
  width: -moz-max-content; /* Firefox/Gecko */
  width: max-content; /* Chrome */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
}
.k-btn:disabled {
  cursor: not-allowed;
}

.k-btn-primary {
  background: var(--kermony-office-rose);
  color: var(--kermony-blanc);
}
.k-btn-primary.k-btn-primary-shadow {
  box-shadow: 0px 2px 8px rgba(109, 45, 104, 0.3);
}

.k-btn-primary:disabled {
  background: var(--kermony-gris-1);
}
.k-btn-primary:hover {
  box-shadow: 0px 2px 8px rgba(109, 45, 104, 0.3);
}

.k-btn-primary:disabled:hover {
  box-shadow: 0px 2px 8px rgb(192, 194, 196);
}
.k-btn-primary:focus {
  background: var(--kermony-office-violet);
}
.k-btn-primary svg {
  stroke: var(--kermony-blanc);
}

.k-btn-secondary {
  border: 1px solid #ac2369;
  border-radius: 5px;
  color: var(--kermony-office-rose);
}
.k-btn-secondary:hover {
  box-shadow: 0px 2px 8px rgba(109, 45, 104, 0.3);
}
.k-btn-secondary:focus {
  border-color: var(--kermony-office-violet);
  color: var(--kermony-office-violet);
}
.k-btn-secondary:focus svg {
  stroke: var(--kermony-office-violet);
}
.k-btn-secondary svg {
  stroke: var(--kermony-office-rose);
}

.k-btn-tertiary {
  color: var(--kermony-office-rose);
}
.k-btn-tertiary:focus {
  color: var(--kermony-office-violet);
}
.k-btn-tertiary:focus svg {
  stroke: var(--kermony-office-violet);
}
.k-btn-tertiary svg {
  stroke: var(--kermony-office-rose);
}

.k-btn-tertiary:disabled {
  color: var(--kermony-gris-1);
}
.k-btn-tertiary:disabled svg {
  stroke: var(--kermony-gris-1);
}

.k-btn-medium {
  padding: 8px 24px;
  height: 40px;
}

.k-btn-small {
  padding: 4px 12px;
  height: 32px;
}

.k-btn-large {
  padding: 12px 36px;
  height: 48px;
}
</style>
