<template>
  <table class="k-table-2">
    <slot />
  </table>
</template>

<script>
export default {
  name: "Table2",
};
</script>

<style>
.k-table-2 {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  text-align: left;
}
.k-table-2 tr {
  height: 56px;
}

.k-table-2 tbody tr {
  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .k-table-2 tbody tr {
    display: block;
    margin-bottom: 16px;
  }

  .k-table-2 tbody tr:last-child {
    margin-bottom: 0px;
  }
}

.k-table-2 td {
  background: var(--kermony-blanc);
}

.k-table-2 td,
.k-table-2 th {
  padding: 8px;
}

.k-table-2 tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.k-table-2 tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
