<template>
  <span
    class="subtitle1"
    :class="{ [`color-kermony-${color}`]: color !== undefined }"
    ><slot></slot
  ></span>
</template>

<script>
export default {
  name: "Subtitle1",
  props: ["color"],
};
</script>

<style scoped>
.subtitle1 {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--kermony-gris-1);
}
</style>
