<template>
  <div class="right-tab-container"><slot /></div>
</template>

<script>
export default {
  name: "RightTabContainer",
};
</script>

<style scoped>
.right-tab-container {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 8px;
}
</style>
