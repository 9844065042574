import _ from "lodash";
import CustomerApi from "@/api/customer";
//import store from "@/store/index";

let avatarCache = {};

export const getAvatarUrl = async (userId, userName) => {
  if (!userId)
    return getFakeAvatar(userName);

  if (userId in avatarCache) {
    if (avatarCache[userId].expireAt <= new Date()) {
      await getAvatarAndCache(userId);
    }
  } else {
    await getAvatarAndCache(userId);
  }

  return avatarCache[userId].url;
};

const deltaInSeconds = 60;

const getAvatarAndCache = async (userId) => {
  const response = await CustomerApi.getAvatarPresignedUrl(userId);
  const avatarUrl = response.avatar;

  const urlParams = new URLSearchParams(avatarUrl);
  let expires = Number(urlParams.get("X-Amz-Expires"));
  // Prevent polling to quickly
  if (expires < 600) {
    expires = 600;
  }

  expires -= deltaInSeconds;

  var d = new Date();
  d.setSeconds(d.getSeconds() + expires);

  avatarCache[userId] = {
    url: avatarUrl,
    expireAt: d,
  };
};

const getFakeAvatar = (firstName) => {
  const colors = [
    "#AC2369",
    "#E0A3B9",
    "#D78CA9",
    "#B84378",
    "#F0D2DD",
    "#C35C88",
    "#E9B9CA",
    "#CD7498",
    "#DA9EB5",
    "#003770",
    "#2E4E7F",
    "#4B5E8F",
    "#65739E",
    "#939CB9",
    "#979FBE",
    "#71C8DA",
  ];

  const index = Math.round(Math.random() * (colors.length - 1));
  const backgroundColor = _.trimStart(colors[index], "#");

  return `https://ui-avatars.com/api/?background=${backgroundColor}&color=fff&name=${firstName}`;
};
