<template>
  <div class="avatar" :style="{ width: `${size}px`, height: `${size}px` }">
    <Icon size="thin" name="users" color="white"></Icon>
  </div>
</template>

<script>
import Icon from "@/components/Icons/Icon";
export default {
  name: "AvatarPlaceholder",
  components: { Icon },
  props: ["src", "size"],
};
</script>

<style scoped>
.avatar {
  border-radius: 5px;
  background-color: var(--kermony-office-rose);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
