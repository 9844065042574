<template>
  <div>
    <RightTabContainer class="aligner">
      <Button size="medium" theme="primary" icon-left="plus" @click="addFamilyMember()">
        Il manque un membre dans ma famille ?
      </Button>
    </RightTabContainer>
    <Table2>
      <thead>
        <tr>
          <th>
            <div
              style="
                display: grid;
                grid-auto-flow: column;
                grid-template-columns: auto 1fr;
              "
            >
              <AvatarPlaceholder size="40" />
              <Body1 class="aligner-v" style="margin-left: 16px">Prénom</Body1>
            </div>
          </th>
          <th>
            <Body1>Nom de famille</Body1>
          </th>
          <th>
            <Body1>Age</Body1>
          </th>
          <th>
            <Body1>Sexe</Body1>
          </th>
          <th>
            <Body1>Accès à l'application?</Body1>
          </th>
          <th>
            <Body1>Action</Body1>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="odd"
          v-for="familyMember in familyMembers"
          :key="familyMember._id"
        >
          <td>
            <Avatar :size="40" :src="getAvatarUrl(familyMember._id)"></Avatar>
            <Body1 style="margin-left: 16px">{{
              familyMember.firstName
            }}</Body1>
          </td>
          <td>
            <Subtitle1>{{ familyMember.lastName }}</Subtitle1>
          </td>
          <td>
            <Subtitle1 v-if="!familyMember.deathDate">{{ computeAge(familyMember.birthDate) }}</Subtitle1>
            <Subtitle1 v-if="familyMember.deathDate && familyMember.gender === 'male'">Décédé</Subtitle1>
            <Subtitle1 v-if="familyMember.deathDate && familyMember.gender === 'female'">Décédée</Subtitle1>
          </td>
          <td style="" v-if="familyMember.gender === 'male'">
            <a-tooltip color="rgb(64 64 64)">
              <template #title>Homme</template>
              <Icon
                size="medium"
                name="male"
                color="var(--kermony-office-rose)"
              />
            </a-tooltip>
          </td>
          <td style="" v-else-if="familyMember.gender === 'female'">
            <a-tooltip color="rgb(64 64 64)">
              <template #title>Femme</template>
              <Icon
                size="medium"
                name="female"
                color="var(--kermony-office-rose)"
              />
            </a-tooltip>
          </td>
          <td v-else></td>

          <td style="" v-if="familyMember.isAllowedToConnect">
            <a-tooltip color="rgb(64 64 64)">
              <template #title>Oui</template>
              <Icon
                size="medium"
                name="badge"
                color="var(--kermony-office-rose)"
              />
            </a-tooltip>
          </td>
          <td style="" v-else>
            <a-tooltip color="rgb(64 64 64)">
              <template #title>Non</template>
              <Icon
                size="medium"
                name="minus"
                color="var(--kermony-office-rose)"
              />
            </a-tooltip>
          </td>
          <td>
            <a-tooltip color="rgb(64 64 64)" v-if="!familyMember.isAllowedToConnect" >
              <template #title>Donner accès à l'application</template>
              <Icon 
                @click="setAdminWaitingConfirmation(familyMember)"
                size="medium"
                name="plus"
                color="var(--kermony-office-rose)" 
              />
            </a-tooltip>
          </td>
        </tr>
      </tbody>
    </Table2>

    <a-modal
      v-model:visible="modalConfirmationVisible"
      title="Donner accès à l'application"
      @ok="handleOk"
      okText="Confirmer"
      cancelText="Annuler"
    >
      <div v-if="setAdminWaitingConfirmationUser !== null">
        <p>
          En poursuivant
          {{ setAdminWaitingConfirmationUser.firstName }}
          {{ setAdminWaitingConfirmationUser.lastName }} aura accès aux données
          financières de votre famille. Un email sera adressé à
          <strong>{{ setAdminWaitingConfirmationUser.email }}</strong>
          afin de configurer un mot de passe sur son compte.
        </p>
      </div>
    </a-modal>

    <a-modal
      v-model:visible="modalMissingEmail"
      title="Email manquant !"
      @ok="closeModalMissingEmail()"
      okText="Fermer"
      :cancelButtonProps="{ style: { display: 'none' } }"
    >
      <div v-if="setAdminWaitingConfirmationUser !== null">
        <p>
          {{ setAdminWaitingConfirmationUser.firstName }}
          {{ setAdminWaitingConfirmationUser.lastName }} ne peut pas avoir de compte car aucune adresse email n'est renseignée. Veuillez
          contacter l'équipe Kermony
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import useVuelidate from "@vuelidate/core";

import FamilyApi from "@/api/family";
import CustomerApi from "@/api/customer";

import router from "@/router";
import moment from "moment-timezone";
import { ref } from "vue";
import { map } from "lodash";
import Avatar from "@/components/Avatars/Avatar";
import Body1 from "@/components/Texts/Body1";
import AvatarPlaceholder from "@/components/Avatars/AvatarPlaceholder";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Icon from "@/components/Icons/Icon";
import Table2 from "@/components/Tables/Table2";
import RightTabContainer from "@/components/Tabs/RightTabContainer";
import Button from "@/components/Buttons/Button";
import { getAvatarUrl } from "@/utils/avatar";

export default {
  components: {
    Button,
    RightTabContainer,
    Table2,
    Icon,
    Subtitle1,
    AvatarPlaceholder,
    Body1,
    Avatar,
  },
  data() {
    return {
      familyMembers: [],
      finalData: {},
      form: {
        user: {},
        family: {},
        matrimonial: {},
        custom: {
          isAdopted: "non",
        },
      },
      currentStep: 1,
    };
  },

  setup() {
    const modalConfirmationVisible = ref(false);

    const showModalConfirmationVisible = () => {
      modalConfirmationVisible.value = true;
    };

    const modalMissingEmail = ref(false);
    const setAdminWaitingConfirmationUser = ref(null);

    const showModalMissingEmail = (user) => {
      setAdminWaitingConfirmationUser.value = user;
      modalMissingEmail.value = true;
    };
    const closeModalMissingEmail = () => {
      modalMissingEmail.value = false;
    };

    const setAdminWaitingConfirmation = (user) => {
      if (!user.email) {
        showModalMissingEmail(user);
        return;
      }

      setAdminWaitingConfirmationUser.value = user;
      showModalConfirmationVisible();
    };
    const closeModalConfirmation = () => {
      modalConfirmationVisible.value = false;
    };

    return {
      v$: useVuelidate(),
      showModalConfirmationVisible,
      modalConfirmationVisible,
      showModalMissingEmail,
      modalMissingEmail,
      setAdminWaitingConfirmation,
      setAdminWaitingConfirmationUser,
      closeModalConfirmation,
      closeModalMissingEmail,
    };
  },

  created() {
    this.fetchFamilyMembers();
  },

  validations() {
    return {
      familyMembers: [],
    };
  },

  methods: {
    getAvatarUrl,
    handleOk() {
      this.setAdmin(this.setAdminWaitingConfirmationUser._id);
      this.closeModalConfirmation();
    },
    formatDate(d) {
      return moment(d).format("DD/MM/YYYY");
    },
    computeAge(d) {
      return moment().diff(d, "years");
    },
    getNameWithUserId(userId) {
      const index = this.familyMembers.findIndex((link) => link._id === userId);

      if (index !== -1)
        return `${this.familyMembers[index].firstName} ${this.familyMembers[index].lastName}`;

      return false;
    },

    displayRegimeMatrimonial() {
      if (this.form && this.form.family.linkType === "partner") return true;

      return false;
    },

    getCustomWording(value) {
      if (value === true) return "Oui";

      return "Non";
    },

    areParentsSelectable() {
      if (!this.form.family.linkedTo) return true;

      if (this.form.family.linkedTo.length > 1) return false;

      return true;
    },

    getFamilyTypeWording(stepName) {
      switch (stepName) {
        case "children":
          return "l'enfant";
        case "parent":
          return "le parent";
        case "conjoint":
          return "le conjoint ou partenaire";
      }
    },

    isActiveMenu(stepName) {
      return { "wizard-step-active": stepName === this.currentStep };
    },

    isCurrentStep(stepName) {
      return stepName === this.currentStep;
    },

    isMariage() {
      if (this.form && this.form.matrimonial.type === "mariage") return true;

      return false;
    },

    isPACS() {
      if (this.form && this.form.matrimonial.type === "pacs") return true;

      return false;
    },

    previousStep() {
      this.currentStep--;
      this.form = this.finalData;

      if (this.currentStep < 1) {
        this.currentStep = 1;
        return router.push({ name: "family.view" });
      }
    },

    setAdmin(id) {
      CustomerApi.setAdmin(id).then(() => {
        this.familyMembers = map(this.familyMembers, (familyMember) => {
          if (familyMember._id === id) {
            return { ...familyMember, isAllowedToConnect: true };
          }

          return familyMember;
        });
      });
    },

    addFamilyMember() {
      FamilyApi.createFamilyMember().then(() => {
        alert("La demande à bien été envoyée, l'équipe Kermony reviendra vers vous rapidement.");
      }).catch((error) => {
        if (error.response)
          console.log(
            "[addFamilyMember]",
            "Error from API:",
            error.response.data.message
          );
      });
    },

    fetchFamilyMembers() {
      FamilyApi.getFamilyMembers()
        .then((data) => {
          this.familyMembers = data.familyMembers;

          for (let i = 0; i < this.familyMembers.length; i++)
            this.familyMembers[i].fullName = this.getNameWithUserId(
              this.familyMembers[i]._id
            );
        })
        .catch((error) => {
          if (error.response)
            console.log(
              "[getFamilyMembers]",
              "Error from API:",
              error.response.data.message
            );
        });
    },
  },
};
</script>

<style scoped>
.vs__dropdown-toggle {
  border: none !important;
}

.v-select {
  padding: 6px !important;
}

.vs__selected {
  font-family: Poppins, Helvetica, sans-serif !important;
}
</style>
