<template>
  <span class="body1"><slot></slot></span>
</template>

<script>
export default {
  name: "Body1",
};
</script>

<style scoped>
.body1 {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: var(--kermony-office-bleu-1);
}
</style>
